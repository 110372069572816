<template>
  <div class="d-flex flex-column flex-root">
    <b-modal
      ref="my-modalContatoPage"
      hide-footer
      centered
      id="contatoPopup"
      title="Contato"
    >
      <div class="row">
        <div class="col-md-12">
          <h5>Email: goantonio80@gmail.com</h5>
          <h5>Celular: (84) 99644-8111</h5>
        </div>
      </div>
    </b-modal>
    <b-modal
      ref="my-modalEquipePage"
      hide-footer
      centered
      id="modal-equipe"
      title="Equipe"
    >
      <div class="row">
        <div class="col-md-12">
          <h5>UFERSA E DUNNAS technology - 2020</h5>
        </div>
      </div>
    </b-modal>
    <b-modal
      ref="my-modalTermosPage"
      hide-footer
      centered
      id="modal-termos"
      title="Termos"
    >
      <div class="row">
        <div class="col-md-12">
          <h5>Nenhum termo ainda foi definido</h5>
        </div>
      </div>
    </b-modal>
    <div
      class="
        login login-1
        d-flex
        flex-column flex-lg-row flex-column-fluid
        bg-white
      "
      :class="{
        'login-signin-on': this.state == 'signin',
        'login-signup-on': this.state == 'signup',
        'login-forgot-on': this.state == 'forgot'
      }"
      id="kt_login"
    >
      <!--begin::Aside-->
      <div
        class="login-aside d-flex flex-column flex-row-auto"
        style="background-color: #1dd1a1"
      >
        <div class="d-flex flex-column-auto flex-column pt-lg-40 pt-15 mb-4">
          <h1
            class="font-weight-bolder text-center font-size-h4 font-size-h1-lg"
            style="color: #fff"
          >
            Bem-vindo ao ambulatório da <br />
            UFERSA
          </h1>
        </div>
        <div
          class="
            aside-img
            d-flex
            flex-row-fluid
            bgi-no-repeat bgi-position-y-top bgi-position-x-center
          "
          :style="{
            backgroundImage: `url(media/novaImagens/telaInicial.svg)`
          }"
        ></div>
      </div>
      <!--begin::Aside-->
      <!--begin::Content-->
      <div
        class="
          login-content
          flex-row-fluid
          d-flex
          flex-column
          justify-content-center
          position-relative
          overflow-hidden
          p-7
          mx-auto
        "
      >
        <div class="d-flex flex-column-fluid flex-center">
          <!--begin::Signin-->
          <div class="login-form login-signin">
            <form
              class="form"
              novalidate="novalidate"
              id="kt_login_signin_form"
              @submit.stop.prevent="onSubmitLogin()"
            >
              <div class="pb-13 pt-lg-0 pt-5">
                <h3
                  class="
                    font-weight-bolder
                    text-dark
                    font-size-h4 font-size-h1-lg
                  "
                >
                  <div class="d-flex flex-column-fluid flex-center">
                    <a href="#" class="text-center mb-10">
                      <img
                        src="media/novaImagens/logo.png"
                        class="max-h-200px max-w-250px"
                        alt=""
                      />
                    </a>
                  </div>

                  Projeto LORD
                </h3>
                <!--
                <span class="text-muted font-weight-bold font-size-h4"
                  >Novo aqui?
                  <a
                    id="kt_login_signup"
                    class=" font-weight-bolder text-hover-success"
                    style="color: #1dd1a1"
                    @click="showForm('signup')"
                    >Crie a sua conta</a
                  ></span
                > -->
              </div>
              <div class="form-group">
                <label class="font-size-h6 font-weight-bolder text-dark"
                  >Email</label
                >
                <div
                  id="example-input-group-1"
                  label=""
                  label-for="example-input-1"
                >
                  <input
                    placeholder="Digite o seu email ou usuário"
                    class="
                      form-control form-control-solid
                      h-auto
                      py-7
                      px-6
                      rounded-lg
                    "
                    type="text"
                    name="email"
                    ref="email"
                    v-model="form.email"
                  />
                </div>
              </div>
              <div class="form-group">
                <div class="d-flex justify-content-between mt-n5">
                  <label class="font-size-h6 font-weight-bolder text-dark pt-5"
                    >Senha</label
                  >
                  <!--
                  <a
                    
                    style="color: #1dd1a1"
                    class="font-size-h6 font-weight-bolder text-hover-success pt-5"
                    id="kt_login_forgot"
                    @click="showForm('forgot')"
                    >Esqueceu a senha ?</a
                  > -->
                </div>
                <div
                  id="example-input-group-2"
                  label=""
                  label-for="example-input-2"
                >
                  <input
                    placeholder="Digite a sua senha"
                    class="
                      form-control form-control-solid
                      h-auto
                      py-7
                      px-6
                      rounded-lg
                    "
                    type="password"
                    name="password"
                    ref="password"
                    v-model="form.password"
                    autocomplete="off"
                  />
                </div>
              </div>
              <div class="pb-lg-0 pb-5">
                <button
                  ref="kt_login_signin_submit"
                  class="
                    btn btn-success
                    font-weight-bolder font-size-h6
                    px-15
                    py-4
                    my-3
                    mr-3
                  "
                >
                  Entrar
                </button>
              </div>
            </form>
          </div>
          <!--end::Signin-->
          <!--begin::Signup-->
          <div class="login-form login-signup">
            <form
              class="form"
              novalidate="novalidate"
              id="kt_login_signup_form"
              @submit.stop.prevent="onSubmitRegister()"
            >
              <div class="pb-13 pt-lg-0 pt-5">
                <h3
                  class="
                    font-weight-bolder
                    text-dark
                    font-size-h4 font-size-h1-lg
                  "
                >
                  Cadastrar-se
                </h3>
                <p class="text-muted font-weight-bold font-size-h4">
                  Digite suas informações abaixo para realizar o cadastro
                </p>
              </div>
              <div class="form-group">
                <input
                  class="
                    form-control form-control-solid
                    h-auto
                    py-7
                    px-6
                    rounded-lg
                    font-size-h6
                  "
                  type="text"
                  placeholder="Nome completo"
                  name="fullname"
                  ref="fullname"
                  autocomplete="off"
                />
              </div>
              <div class="form-group">
                <input
                  class="
                    form-control form-control-solid
                    h-auto
                    py-7
                    px-6
                    rounded-lg
                    font-size-h6
                  "
                  type="email"
                  placeholder="Email"
                  name="email"
                  ref="remail"
                  autocomplete="off"
                />
              </div>
              <div class="form-group">
                <input
                  class="
                    form-control form-control-solid
                    h-auto
                    py-7
                    px-6
                    rounded-lg
                    font-size-h6
                  "
                  type="password"
                  placeholder="Senha"
                  name="password"
                  ref="rpassword"
                  autocomplete="off"
                />
              </div>
              <div class="form-group">
                <input
                  class="
                    form-control form-control-solid
                    h-auto
                    py-7
                    px-6
                    rounded-lg
                    font-size-h6
                  "
                  type="password"
                  placeholder="Confirmar senha"
                  name="cpassword"
                  ref="cpassword"
                  autocomplete="off"
                />
              </div>

              <div class="form-group d-flex flex-wrap pb-lg-0 pb-3">
                <button
                  ref="kt_login_signup_submit"
                  class="
                    btn btn-success
                    font-weight-bolder font-size-h6
                    px-8
                    py-4
                    my-3
                    mr-4
                  "
                  style="width: 150px"
                >
                  Cadastrar
                </button>
                <button
                  type="button"
                  id="kt_login_signup_cancel"
                  class="
                    btn btn-light-success
                    font-weight-bolder font-size-h6
                    px-8
                    py-4
                    my-3
                  "
                  @click="showForm('signin')"
                >
                  Cancelar
                </button>
              </div>
            </form>
          </div>
          <!--end::Signup-->
          <!--begin::Forgot-->
          <div class="login-form login-forgot">
            <!--begin::Form-->
            <form
              class="form"
              novalidate="novalidate"
              id="kt_login_forgot_form"
              ref="kt_login_forgot_form"
            >
              <div class="pb-13 pt-lg-0 pt-5">
                <h3
                  class="
                    font-weight-bolder
                    text-dark
                    font-size-h4 font-size-h1-lg
                  "
                >
                  Esqueceu a sua senha?
                </h3>
                <p class="text-muted font-weight-bold font-size-h4">
                  Digite o seu email para resetar a sua senha
                </p>
              </div>
              <div class="form-group">
                <input
                  class="
                    form-control form-control-solid
                    h-auto
                    py-7
                    px-6
                    rounded-lg
                    font-size-h6
                  "
                  type="email"
                  placeholder="Email"
                  name="email"
                  autocomplete="off"
                />
              </div>
              <div class="form-group d-flex flex-wrap pb-lg-0">
                <button
                  type="button"
                  id="kt_login_forgot_submit"
                  class="
                    btn btn-success
                    font-weight-bolder font-size-h6
                    px-8
                    py-4
                    my-3
                    mr-4
                  "
                >
                  Confirmar
                </button>
                <button
                  type="button"
                  id="kt_login_forgot_cancel"
                  class="
                    btn btn-light-success
                    font-weight-bolder font-size-h6
                    px-8
                    py-4
                    my-3
                  "
                  @click="showForm('signin')"
                >
                  Cancelar
                </button>
              </div>
            </form>
          </div>
          <!--end::Forgot-->
        </div>
        <!--begin::Content footer-->
        <div
          class="
            d-flex
            justify-content-lg-start justify-content-center
            align-items-end
            py-7 py-lg-0
          "
        >
          <a
            style="color: #1dd1a1"
            v-b-modal.modal-termos
            class="text-hover-success font-weight-bolder font-size-h5"
            >Termos</a
          >
          <a
            style="color: #1dd1a1"
            v-b-modal.modal-equipe
            class="text-hover-success ml-10 font-weight-bolder font-size-h5"
            >Equipe</a
          >
          <a
            style="color: #1dd1a1"
            v-b-modal.contatoPopup
            class="text-hover-success ml-10 font-weight-bolder font-size-h5"
            >Contato</a
          >
        </div>
        <!--end::Content footer-->
      </div>
      <!--end::Content-->
    </div>
  </div>
</template>

<!-- Load login custom page styles -->
<style lang="scss">
@import "@/assets/sass/pages/login/login-1.scss";
</style>

<script>
import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";

// FormValidation plugins
import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
import SubmitButton from "@/assets/plugins/formvalidation/dist/es6/plugins/SubmitButton";
// import axios from "axios";
import KTUtil from "@/assets/js/components/util";
import { mapGetters, mapState } from "vuex";
import { LOGOUT, REGISTER } from "@/core/services/store/auth.module";
import Swal from "sweetalert2";
import * as ambulatorio from "../../../../core/services/ambulatorioAPI.service";

export default {
  name: "login-1",
  data() {
    return {
      state: "signin",
      form: {
        email: "",
        password: ""
      }
    };
  },
  computed: {
    ...mapState({
      errors: state => state.auth.errors
    }),
    ...mapGetters(["currentUser"]),

    backgroundImage() {
      return (
        process.env.BASE_URL + "media/svg/illustrations/login-visual-1.svg"
      );
    }
  },
  mounted() {
    const signin_form = KTUtil.getById("kt_login_signin_form");
    const signup_form = KTUtil.getById("kt_login_signup_form");
    const forgot_form = KTUtil.getById("kt_login_forgot_form");

    this.fv = formValidation(signin_form, {
      fields: {
        email: {
          validators: {
            notEmpty: {
              message: "É necessário digitar o email para continuar"
            }
          }
        },
        password: {
          validators: {
            notEmpty: {
              message: "É necessário digitar a senha para continuar"
            }
          }
        }
      },
      plugins: {
        trigger: new Trigger(),
        submitButton: new SubmitButton(),
        bootstrap: new Bootstrap()
      }
    });

    this.fv1 = formValidation(signup_form, {
      fields: {
        fullname: {
          validators: {
            notEmpty: {
              message: "É necessário digitar o nome completo para continuar"
            }
          }
        },
        email: {
          validators: {
            notEmpty: {
              message: "É necessário digitar o email  para continuar"
            },
            emailAddress: {
              message: "O email informado não é válido"
            }
          }
        },
        password: {
          validators: {
            notEmpty: {
              message: "É necessário digitar a senha para continuar"
            }
          }
        },
        cpassword: {
          validators: {
            notEmpty: {
              message: "É necessário confirmar a senha para continuar"
            },
            identical: {
              compare: function() {
                return signup_form.querySelector('[name="password"]').value;
              },
              message: "As senhas digitas estão diferentes"
            }
          }
        }
      },
      plugins: {
        trigger: new Trigger(),
        submitButton: new SubmitButton(),
        bootstrap: new Bootstrap()
      }
    });

    this.fv2 = formValidation(forgot_form, {
      fields: {
        email: {
          validators: {
            notEmpty: {
              message: "É necessário digitar o email para continuar"
            },
            emailAddress: {
              message: "O email digitado não é válido"
            }
          }
        }
      },
      plugins: {
        trigger: new Trigger(),
        submitButton: new SubmitButton(),
        bootstrap: new Bootstrap()
      }
    });
  },
  methods: {
    showForm(form) {
      this.state = form;
      var form_name = "kt_login_" + form + "_form";
      KTUtil.animateClass(
        KTUtil.getById(form_name),
        "animate__animated animate__backInUp"
      );
    },

    async onSubmitLogin() {
      try {
        const response = await ambulatorio.loginUser(
          this.form.email,
          this.form.password
        );
        console.log(response);
        this.fv.validate();

        //guardando o token no localstore para depois usar no controle e acesso de rotas
        window.localStorage.setItem(
          "ambulatorio",
          response.data.usuario.auth_token
        );

        window.localStorage.setItem(
          "tipoUsuario",
          response.data.usuario.usuario_de_type
        );

        window.localStorage.setItem("username", response.data.usuario.username);
        window.localStorage.setItem("email", response.data.usuario.email);

        if (response.data.usuario.usuario_de_type != "Administrador") {
          window.localStorage.setItem(
            "setor",
            response.data.usuario.setor.descricao
          );
        }

        this.fv.on("core.form.valid", () => {
          // clear existing errors
          this.$store.dispatch(LOGOUT);

          // set spinner to submit button
          const submitButton = this.$refs["kt_login_signin_submit"];
          submitButton.classList.add(
            "spinner",
            "spinner-light",
            "spinner-right"
          );

          var tipo = localStorage.getItem("tipoUsuario");
          var rota = "dashboard";

          if (tipo == "Aluno") {
            rota = "filaAtendimentoConsulta";
          } else if (tipo == "Administrador") {
            rota = "painelPacientes";
          } else if (tipo == "Enfermeiro") {
            rota = "filaDeAtendimento";
          } else if (tipo == "Medico") {
            rota = "filaAtendimentoConsulta";
          } else if (tipo == "Recepcionista") {
            rota = "painelPacientes";
          }

          setTimeout(() => {
            this.$router.push({ name: rota });

            submitButton.classList.remove(
              "spinner",
              "spinner-light",
              "spinner-right"
            );
          }, 2000);
        });
      } catch (err) {
        console.log({ err });

        Swal.fire({
          title: "",
          text: "Erro ao realizar login, verifique as informações",
          icon: "error",
          confirmButtonClass: "btn btn-secondary",
          heightAuto: false
        });
      }
    },

    onSubmitRegister() {
      this.fv1.validate();

      this.fv1.on("core.form.valid", () => {
        const email = this.$refs.remail.value;
        const password = this.$refs.rpassword.value;

        // clear existing errors
        this.$store.dispatch(LOGOUT);

        // set spinner to submit button
        const submitButton = this.$refs["kt_login_signup_submit"];
        submitButton.classList.add("spinner", "spinner-light", "spinner-right");

        // dummy delay
        setTimeout(() => {
          // send register request
          this.$store
            .dispatch(REGISTER, {
              email: email,
              password: password
            })
            .then(() => this.$router.push({ name: "dashboard" }));

          submitButton.classList.remove(
            "spinner",
            "spinner-light",
            "spinner-right"
          );
        }, 2000);
      });

      this.fv1.on("core.form.invalid", () => {
        Swal.fire({
          title: "",
          text: "Por favor, informe as informações corretamente para continuar",
          icon: "error",
          confirmButtonClass: "btn btn-secondary",
          heightAuto: false
        });
      });
    }
  }
};
</script>
